import axios from 'axios'
import store from '@/store'

export const postConsent = async function (payload, analysisType) {
    if(store.state.currentUser.is_anon){
        return await axios.post(`${process.env.VUE_APP_RECORD_URL}/consent/anonymous/${store.state.anonId}?analysisType=${analysisType}`, payload)
    }
    return await axios.post(`${process.env.VUE_APP_RECORD_URL}/consent/organic`, payload)
    
}
export const postTrailPersonalInfo = async function (payload){
    return await axios.post(`${process.env.VUE_APP_RECORD_URL}/trial/participant-info`, payload)
}

export const postTrialConsent = async function (payload, analysisType) {
    return await axios.post(`${process.env.VUE_APP_RECORD_URL}/trial/consent/participant?analysisType=${analysisType}`, payload)
}

export const getConsentByParticipantId = async function () {
    // TODO
    if (store.state.currentUser.is_anon) {
        return await axios.get(`${process.env.VUE_APP_RECORD_URL}/survey/questionaire/anonymous/${store.state.anonId}`)
    }
    return await axios.get(`${process.env.VUE_APP_RECORD_URL}/survey/questionaire/organic`)
}


export const defaultConsent = [
    {
        id: 1,
        question: "survey.consent.invitation.question1",
        answer: null,
        answerFieldType: "RADIO_BUTTON",
        options: [
            {label: "survey.buttons.yes", value: "Yes"},
            {label: "survey.buttons.no", value: "No"},
        ]
    },
    {
        id: 2,
        question: "survey.consent.invitation.question2",
        answer: null,
        answerFieldType: "RADIO_BUTTON",
        options: [
            {label: "survey.buttons.yes", value: "Yes"},
            {label: "survey.buttons.no", value: "No"},
        ]
    },
    {
        id: 3,
        question: "survey.consent.invitation.question3",
        answer: null,
        answerFieldType: "RADIO_BUTTON",
        options: [
            {label: "survey.buttons.yes", value: "Yes"},
            {label: "survey.buttons.no", value: "No"},
        ]
    }
]

export const secondConsent = [
    {
        id: 4,
        question: "survey.consent.invitation.agreement1",
        answer: null,
        answerFieldType: "RADIO_BUTTON",
        options: [
            {label: "survey.buttons.yes", value: "Yes"},
            {label: "survey.buttons.no", value: "No"},
        ]
    },
    {
        id: 5,
        question: "survey.consent.invitation.agreement2",
        answer: null,
        answerFieldType: "RADIO_BUTTON",
        options: [
            {label: "survey.buttons.yes", value: "Yes"},
            {label: "survey.buttons.no", value: "No"},
        ]
    },
    {
        id: 6,
        question: "survey.consent.invitation.question3",
        answer: null,
        answerFieldType: "RADIO_BUTTON",
        options: [
            {label: "survey.buttons.yes", value: "Yes"},
            {label: "survey.buttons.no", value: "No"},
        ]
    }
]

export const italianSecondConsent = [
    {
        id: 4,
        question: "survey.consent.invitation.agreement1",
        answer: null,
        answerFieldType: "RADIO_BUTTON",
        options: [
            {label: "survey.buttons.yes", value: "Yes"},
            {label: "survey.buttons.no", value: "No"},
        ]
    },
    {
        id: 5,
        question: "survey.consent.invitation.agreement2",
        answer: null,
        answerFieldType: "RADIO_BUTTON",
        options: [
            {label: "survey.buttons.yes", value: "Yes"},
            {label: "survey.buttons.no", value: "No"},
        ]
    }

]


export default {postConsent, postTrialConsent, postTrailPersonalInfo, defaultConsent, getConsentByParticipantId }