const ID_TOKEN_KEY = "access_token";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem("refresh_token")
};

export const saveToStorage = (key, token) => {
  window.localStorage.setItem(key, token);
};

export const getFromStorage = (key, token) => {
  window.localStorage.getItem(key, token);
};

export const destroyItemInStorage = (key, token) => {
  window.localStorage.removeItem(key, token);
};

export const isTokenExpired = () => {
  return !window.localStorage.getItem(ID_TOKEN_KEY) || Date.now() > +window.localStorage.getItem("exp");
}

export default { getToken, saveToken, destroyToken, getFromStorage, saveToStorage, destroyItemInStorage, isTokenExpired };
