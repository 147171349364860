<template>
  <div
    class="card voicemed-card voicemed-card-centered-medium terms-and-condition-card"
  >
    <div class="close-btn-div" v-if="isPopup">
      <button class="close-btn" @click="closeModal()">Close</button>
    </div>
    <div class="question-section">
      <!-- enter here -->
      <div class="card-top card-content survey-info-card">
        <div>
          <section class="text-description">
            <h2 class="is-size-4 title has-text-centered has-text-weight-bold">
              {{ termsAndConditions.title }}
            </h2>
            <p
              v-if="termsAndConditions.version"
              class="has-text-centered version-info"
            >
              <i>{{ termsAndConditions.version }}</i>
            </p>
            <br />
            <div
              v-for="(section, index) of termsAndConditions.sections"
              :key="index"
            >
              <h3 class="question-header">
                {{ section.title }}
              </h3>
              <div>
                <p
                  class="small-pg mb-2"
                  v-for="(paragraph, index) of section.paragraphs"
                  :key="index"
                  v-html="paragraph"
                ></p>
              </div>
            </div>
          </section>
        </div>
        <div class="mt-5 mb-5 px-3 py-2" v-if="!hideOption">
          <checkbox-v-1
            :choice="choice"
            @output="isConsentGiven = $event"
          ></checkbox-v-1>
        </div>
      </div>

      <div class="card-bottom bottom w-full" v-if="!hideOption">
        <footer class="card-footer">
          <div class="card-footer-item">
            <b-button size="is-large mr-2" @click="previous()">
              <img src="/images/back.svg" />
            </b-button>
            <b-button
              size="is-large"
              class="voicemed-button next-button ml-2"
              :disabled="!isConsentGiven"
              @click.native="next()"
              >{{ $t("survey.buttons.next") }}</b-button
            >
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import CheckboxV1 from "@/core/components/ui/form-fields-ui/CheckboxV1.vue";
export default {
  components: { CheckboxV1 },
  name: "TermsAndConditions",
  props: {
    termsAndConditions: {
      type: Object,
      default: () => ({
        title: "Terms and conditions",
        version: null,
        sections: [
          {
            title: "1. Hi, we are VoiceMed",
            paragraphs: [
              "1.1. The following terms and conditions (hereinafter referred to as “Terms and Conditions”) form part of the agreement between you and VoiceMed Italia Srl (hereinafter referred to as “VoiceMed”, “we”, “our” or “us”), the operator of a platform that gives you, (hereinafter referred to as “you”, “your” or “User”), access to our health application and any related services. Our health application offers many features including a COVID-19 test based on sound analysis.",
              "1.2. The health application is available as a web-based application that can be accessed via your mobile device, computer or tablet (hereinafter called the “VoiceMed Web App”).",
              "1.3. The health application includes a test to detect respiratory diseases, specifically COVID-19, from the analysis of sound samples (hereinafter called the “VoiceMed COVID-19 Test”).",
              "PLEASE NOTE THAT THE VOICEMED WEP APP, OR THE VOICEMED COVID-19 TEST,  DOES NOT MAKE ANY MEDICAL DIAGNOSES. PLEASE SEEK THE ADVICE OF A MEDICAL PROFESSIONAL IF YOU ARE CONCERNED ABOUT YOUR HEALTH. IN CASE OF AN EMERGENCY, YOU SHOULD CONTACT THE EMERGENCY SERVICES IMMEDIATELY. ALWAYS CONSULT YOUR DOCTOR OR ANOTHER QUALIFIED HEALTH CARE PROVIDER IF YOU HAVE ANY QUESTIONS REGARDING ANY MEDICAL ISSUES. YOU SHOULD NEVER DISREGARD A DOCTOR’S PROFESSIONAL ADVICE OR CANCEL AN APPOINTMENT WITH A DOCTOR BECAUSE YOU ARE RELYING ON INFORMATION PROVIDED BY THE VOICEMED WEB APP.",
              "1.4. The VoiceMed Web App is operated by VoiceMed Italian Srl, a company founded in Italy and registered under the commercial number REA RM-1631928, and with its registered office at Via Salvatore De Giacomo 66, 00142, Rome, Italy.",
              "1.5. Your access to the VoiceMed Web App is being mediated by a 3rd party that has a subscription with VoiceMed in which you are included (hereinafter called Provider).",
              "1.6. You must be at least 18 years old if you wish to use the VoiceMed Web App.",
            ],
          },
          {
            title: "2. The legal relationship between you and VoiceMed",
            paragraphs: [
              "2.1. These Terms and Conditions apply to the use of the VoiceMed Web App and contain the terms governing your use of the VoiceMed Web App. It is important that you have read and understood the present Terms and Conditions in conjunction with our Privacy Policy before using the VoiceMed Web App.",
              "2.2 By using the VoiceMed Web App in any way, you are agreeing to comply and be bound by these Terms and Conditions, as well as our Privacy Policy.",
            ],
          },
          {
            title: "3. Information we collect about you",
            paragraphs: [
              "3.1. At VoiceMed, we care about your privacy and  protecting any data relating to you (hereinafter referred to as “Personal Data”). Therefore, we are committed to the protection of your Personal Data in accordance with the General Data Protection Regulation 2016/679 of April 27th, 2016 (“GDPR”) and the Personal Data Protection Code (Legislative Decree 196/2003). Please read our Privacy Policy to understand how, and for what purposes, we collect and use your information to provide you with the best possible service.",
              "3.2. It is possible to irreversibly alter data so that the data can no longer be connected to you. This process is called data anonymization and the resulting data is no longer considered Personal Data. You hereby grant us the perpetual, worldwide, transferable, and sublicensable right to use data, which we have anonymized in line with applicable data protection law, in any manner, whether currently known or unknown. You acknowledge and accept that we own all right, title and interest in and to any data derived by us from such anonymized data.",
            ],
          },
          {
            title: "4. The VoiceMed Web App",
            paragraphs: [
              "4.1. The VoiceMed Web app offers you, among other features, the opportunity to find out if there are signs of certain diseases, such as COVID-19, following the analysis of your sound samples.",
              "4.3. Please be aware that the results from the VoiceMed COVID-19 Test,  as well as any information obtained directly and indirectly in the VoiceMed Web App, are not a suitable substitute for medical advice obtained from your doctor, general practitioner or other health service provider.",
              "4.4 The VoiceMed COVID-19 Test does NOT provide a diagnosis.",
              "4.5 The result of the VoiceMed COVID-19 Test cannot be taken as guidance for not following local COVID-19 guidelines or COVID-19 protective measures adopted by local public health authorities or by the Provider that is mediating your right to access the VoiceMed Web App.",
              "4.6. Ultimately, you are responsible for whether or not you decide to consult a medical professional and/or seek medical advice.",
            ],
          },
          {
            title: "5. Your right to use the VoiceMed Web App",
            paragraphs: [
              "5.1. The Ada Platform and all materials and content contained therein are our property or the property of the persons who have granted us the applicable license (this also applies to the third-party software listed in the Ada App). We grant you the right to use these materials and content, but only for the purposes of using the Ada Platform, and only for you personally in accordance with these Terms and Conditions.",
              "5.2. You have the right to personally access and use the VoiceMed Web App through a contract established with your Provider. You are prohibited from transferring this right to anyone else, let anyone else use the VoiceMed Web App on your behalf.",
              "5.3. Feedback/surveys. If you provide feedback, ideas or suggestions, or if you reply to surveys in connection with our Services (hereinafter referred as the “Feedback”), you acknowledge that the Feedback is not confidential and that you grant us a worldwide, non-exclusive, irrevocable, perpetual, royalty free and unlimited license to use your Feedback in any way, for any purpose, and through any medium or technology now known or unknown, whether in whole or in part, and whether as modified or unmodified. We will always use your Feedback in compliance with those Terms and Conditions, our Privacy Policy and any other applicable laws",
            ],
          },
          {
            title: "6. What we promise to you",
            paragraphs: [
              "6.1. The access to VoiceMed Web App requires an internet connection and device such as an smartphone or personal computer. As such, we cannot guarantee that you will be able to use the VoiceMed Web App at all times without any interruption and without delay or faults. We can therefore assume no obligation with respect to the performance or availability of the VoiceMed Web App in these present Terms and Conditions.",
              "6.2. Should you incur any damage through the use of the VoiceMed Web App, we may only be held liable for intent and gross negligence. We may furthermore be held liable for the negligent breach of material contractual obligations, which must be met in order for this agreement to even be properly executed, and the violation of which will jeopardise the purpose of the agreement and which you, the User, should in regular cases be able to trust will be fulfilled. In the latter case, we may however only be held liable for calculable damages. We may not be held liable for violation by simple negligence of any obligations other than those specified in the preceding sentences. The foregoing exclusions of liability do not apply to violations causing death or personal injury and any liability under the Italian Consumer Code.",
            ],
          },
          {
            title: "7. What you are promising us",
            paragraphs: [
              "7.1 The following is a list of activities that are prohibited in the context of using the VoiceMed Web App. You are prohibited to:<br>- Circumvent, disable, or otherwise manipulate any of the VoiceMed Web App’s security features or any features preventing or restricting the use or copying of the content that is available via the VoiceMed Web App,<br>- Provide false or misleading information,<br>- Allow anyone else to use the VoiceMed Web App on your behalf or in your place,<br>- Act in any unlawful or illegal manner,<br>- Modify, interfere with, hack or disrupt the VoiceMed Web App, or to intercept messages,<br>- Extract data from the VoiceMed Web App except as permitted under these Terms and Conditions,<br>- Enter information about any third-party without the consent of the third-party concerned.",
              "7.2. The non-compliance with any of the items listed in paragraph 8.1 represents a material breach of these Terms and Conditions, and may result in us, at our sole discretion, initiating the following measures – either individually or in combination:<br>The immediate, temporary suspension or permanent withdrawal of the right to access the Ada Platform,<br>A warning being sent to you,<br>Initiation of legal proceedings against you, including a claim for reimbursement of any costs and expenses incurred due to the breach of agreement (including reasonable administrative costs and legal fees), and moreover,<br>Disclosure of information to the law enforcement authorities, if and to the extent permitted by law and deemed by us to be necessary.",
              "7.3. The reaction to a breach of agreement committed by you is not limited to the actions described in the preceding paragraph 8.2, i.e. we are entitled to take any further action in accordance with these Terms and Conditions and/or statutory law.",
            ],
          },
          {
            title: "8. Terminating your relationship with VoiceMed",
            paragraphs: [
              "8.1. You hereby agree to stop using the VoiceMed Web App immediately in the event that you feel that you can no longer agree to the present Terms and Conditions or the Privacy Policy.",
              "8.2. You are entitled to stop using the VoiceMed Web App at any time and to terminate your agreement with VoiceMed.",
              "8.3. Either party’s right to terminate the agreement for cause remains unaffected. Cause that entitles us to terminate the agreement may be, in particular but not exclusively, your violation of the material contractual provisions (in particular paragraph 7.2) and if, due to external circumstances, it can be assumed that you are making improper use of our services. If good reason exists, we are furthermore authorised to block your access to the VoiceMed Web App, without any notice, and provided that no other less severe measure is effective.",
            ],
          },
          {
            title:
              "9. Documents governing the contractual relationship between you and us",
            paragraphs: [
              "9.1 The current version of the Terms and Conditions includes all provisions governing the contractual relationship between you and us. Older versions of the Terms and Conditions no longer apply to our contractual relationship and are entirely replaced by the current version.",
            ],
          },
          {
            title: "10. Applicable legislation and jurisdiction",
            paragraphs: [
              "10.1. The agreement concluded on the basis of these Terms and Conditions is subject to the laws of the Italian Republic.",
              "10.2. The courts of Italy have the exclusive jurisdiction to settle any disputes arising in connection with, or as a result of, your use of the VoiceMed Web App.",
            ],
          },
          {
            title: "11. Last but not least, please reach out to us",
            paragraphs: [
              "If you wish to contact us, don’t hesitate to reach out by email at contact@voicemed.io. We can reach out to us in Italian or English with questions or comments about these Terms and Conditions. You can also write us a letter and send it to our registered office at Via Salvatore De Giacomo 66, 00142, Rome, Italy.",
            ],
          },
        ],
        agreement:
          "I have read and I accept Terms of Service and Privacy Policy.",
      }),
    },
    hideOption: { type: Boolean, default: false },
    isPopup: { type: Boolean, default: false },
  },
  created() {
    this.choice = {
      label: this.termsAndConditions.agreement,
      value: false,
      name: "consentToTermsAndCondition",
    };
  },
  data: () => ({
    isConsentGiven: false,
    choice: null,
  }),
  methods: {
    next() {
      this.$emit("nextStep");
    },
    previous() {
      this.$emit("previousStep");
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
h3 {
  &.question-header {
    padding-bottom: 16px;
    padding-top: 32px;
    font-size: 16px !important;
    font-weight: bold;
  }
}
.header {
  background-color: white;
  position: absolute;
  top: 0px;
  width: 100%;
  padding-bottom: 32px;
}

.text-description {
  padding-top: 40px;
}

.next-button {
  min-width: 230px !important;
}
.small-pg {
  font-size: 12px;
}

.close-btn-div {
  position: sticky;
  top: 10px;
  margin-right: 20px;
  float: right;
  z-index: 999999;
}

.close-btn {
  outline: none !important;
  cursor: pointer;
  transition: 0.5s;
  border-radius: 5px;
  width: 94px;
  height: 40px;
  background: #fff1f5;
  color: #ff7062;
  font-size: 13px;
  border: none;
}
</style>
