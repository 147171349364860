/* eslint-disable no-unused-vars */
/* eslint-disable no-cond-assign */
export const PassCodeWatcher = {
    inserted : function(el, binding, vnode){
        el.onkeyup = function(e) {

            var target = e.srcElement || e.target;
            var maxLength = parseInt(target.attributes["maxlength"].value, 10);
            var myLength = target.value.length;

            if (myLength >= maxLength) {
                var next = target.nextElementSibling;
                if(next == null){
                    if (vnode.componentInstance) {
                        vnode.componentInstance.$emit("done", {detail: el.children.map(e => e.target.value)}); // use {detail:} to be uniform
                    } else {
                        vnode.elm.dispatchEvent(new CustomEvent("done", {detail: [].slice.call(el.children).map(d => d.value).join("")}));
                    }
                    return;
                }else{
                    if (next.tagName.toLowerCase() === "input") {
                        next.focus();
                        return;
                    }
                }
            }
            // Move to previous field if empty (user pressed backspace)
            else if (myLength === 0) {
                var previous = target.previousElementSibling;
                if(previous == null){
                    return;
                }else{
                    if (previous.tagName.toLowerCase() === "input") {
                        previous.focus();
                        return;
                    }
                }
            }
        }
    }
}