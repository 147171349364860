import axios from 'axios'
import store from '@/store'
import commonUtils from '../plugins/utils' 
export const login = async function(credentials){

        const response = await axios.post(`${process.env.VUE_APP_AUTH_URL}/auth/login`, credentials) 
        store.dispatch('setLoggedInAuthUser', response.data)
        store.dispatch('changeAnonId', commonUtils.generateTimestampBasedUniqueID())

} 

export const trialLogin = async function(companyShortId, anonId, analysisType){
        //product code 00001 is COVID_RECORD
        const payload = { analysisType : analysisType, participantId : anonId}
        const response = await axios.post(`${process.env.VUE_APP_AUTH_URL}/auth/ct/login/client/${companyShortId}/product/00001`, payload, {skipAuthRefresh: true }) 
        store.dispatch('setLoggedInAuthUser', response.data)
        store.dispatch('changeAnonId', anonId)
}       

export const directLogin = async function(companyShortId, anonId){
        //product code 00001 is COVID_RECORD
        const response = await axios.get(`${process.env.VUE_APP_AUTH_URL}/auth/quick-access/${companyShortId}/00001`) 
        store.dispatch('setLoggedInAuthUser', response.data)
        store.dispatch('changeAnonId', anonId)
}       

export const refreshToken = async function(){
        const credentials = {
                 access_token : window.localStorage.getItem("access_token"),
                 refresh_token : window.localStorage.getItem("refresh_token")
        }
        const response = await axios.post(`${process.env.VUE_APP_AUTH_URL}/auth/refresh-token`, credentials) 
        store.dispatch('setLoggedInAuthUser', response.data)
        return response

}  

export const quickUrlLogin = async function(credentails){
        const response = await axios.get(`${process.env.VUE_APP_AUTH_URL}/auth/service-login/product/00001?identity=${credentails.id}&hash=${credentails.hash}`) 
        store.dispatch('setLoggedInAuthUser', response.data)
}

export const fetchLoggedInUser = async function(){

    const response = await axios.get(`${process.env.VUE_APP_AUTH_URL}/auth/user`) 
    return response.data

} 

export const logout = function(){
        window.localStorage.removeItem("access_token"),
        window.localStorage.removeItem("refresh_token")
        window.localStorage.removeItem("exp")
}

export default { login, directLogin, trialLogin,  fetchLoggedInUser, quickUrlLogin, refreshToken, logout }