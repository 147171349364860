<template>
  <div class="main-wrapper py-2 px-4 p-lg-0 bg-lg-voicemed">
    <div class="cmp-logo has-text-centered py-4">
      <img
        src="@/assets/media/img/logo/voicemed-reversed.svg"
        alt=""
        srcset=""
      />
    </div>
    <div class="main columns">
      <div class="container">
        <terms-and-conditions
          @previousStep="previous()"
          @nextStep="next()"
          :termsAndConditions="$t('lns.termsAndConditions')"
        ></terms-and-conditions>
      </div>
    </div>
  </div>
</template>
<script>
import TermsAndConditions from "@/core/components/ui/consent-agreement/TermsAndConditions.vue";

export default {
  name: "LnsConsent",
  components: { TermsAndConditions },
  data: () => ({
    participant_id: null
  }),
  methods: {
    next() {
      this.$router.push({ name: "LnsLogin" });
    },
    previous() {
      this.$router.push({ name: "LnsIndex" });
    }
  }
};
</script>
<style scoped>
.small-img {
  max-height: 400px;
}

.congrats-text {
  font-weight: bold;
  font-size: 30px;
  color: #ff4b7f !important;
}
.card {
  min-height: 520px;
  margin-bottom: 2rem;
}
.question-item {
  margin-top: 4rem;
}
.next-button:hover {
  color: #fff !important;
}
@media screen and (max-width: 576px) {
  .card-bottom {
    position: fixed;
    bottom: 0;
  }
  .next-button {
    min-width: 230px !important;
  }
}
</style>
