import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import Buefy from "buefy";

import "@/assets/styles/spacing.scss";
import "@/assets/styles/loading.min.scss";
import "@/assets/styles/ldbtn.min.scss";
import "@/assets/styles/styles.scss";

// import './assets/styles.css'
import AudioRecorder from "vue-audio-recorder";
import i18n from "@/i18n";
import ApiService from "@/core/services/api.service";
import "./registerServiceWorker";

ApiService.init();

Vue.use(AudioRecorder);
Vue.use(Buefy);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
