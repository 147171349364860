<template>
  <div id="base">
    <Header />
    <router-view />
  </div>
</template>
<script>
import Header from "@/views/layout/Header";
import { mapActions, mapState } from "vuex";
import jwtService from "@/core/services/jwt.service";
export default {
  components: {
    Header
  },
  created() {
    if (jwtService.getToken()) {
      this.fetchCurrentUser();
    }
  },
  computed: mapState(["locale"]),
  watch: {
    locale() {
      this.$router.replace({ params: { lang: this.locale } }).catch(() => {});
    }
  },
  methods: {
    ...mapActions(["fetchCurrentUser"])
  }
};
</script>
