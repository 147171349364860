<template>
  <div class="main-wrapper py-2 px-4 p-lg-0 bg-lg-voicemed">
    <div class="cmp-logo has-text-centered py-4">
      <img
        src="@/assets/media/img/logo/voicemed-reversed.svg"
        alt="voicemed logo"
      />
    </div>
    <div class="container">
      <section class="card voicemed-card voicemed-card-centered-medium">
        <div class="card-content">
          <h1 class="title has-text-centered mt-lg-4">
            {{ $t("asl.login.title") }}
          </h1>
          <div class="has-text-centered mb-4">
            <p>{{ $t("asl.login.description") }}</p>
          </div>
          <div>
            <div class="my-5">
              <div class="voicemed-field-wrapper mb-2">
                <b-field
                  :label="$t('asl.login.loginFormLabel') || 'ASL patient Id'"
                  :label-position="'inside'"
                >
                  <b-input
                    class="voicemed-input-field"
                    type="text"
                    v-model="lnsCode"
                    maxlength="10"
                    size="is-medium"
                  ></b-input>
                </b-field>
              </div>
              <transition name="fade">
                <div class="mt-3 error-wrapper" v-if="hasError">
                  <span>{{ errorMessage }}</span>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="card-bottom bottom w-full">
          <footer class="card-footer py-4">
            <div class="card-footer-item">
              <b-button
                size="is-large"
                class="voicemed-button next-button"
                @click.native="submit()"
                >{{ $t("survey.buttons.next") }}</b-button
              >
            </div>
          </footer>
        </div>
      </section>
    </div>
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>
<script>
import { PassCodeWatcher } from "@/core/directives/pass-code-watcher.js";
import authService from "@/core/services/auth.service";
export default {
  name: "ASLLogin",
  directives: {
    passCodeWatcher: PassCodeWatcher
  },
  methods: {
    submit() {
      this.isLoading = true;
      this.hasError = false;
      authService
        .trialLogin(this.companyShortId, this.lnsCode, "COVID_19")
        .then(() => {
          this.startSurvey();
        })
        .catch(error => {
          const errorTitle = error.response.data.title;
          this.errorMessage =
            this.$t(`errorLabels.${errorTitle}`) ||
            error.response.data.detail ||
            this.errorMessage;
          this.isLoading = false;
          this.hasError = true;
          // this.$buefy.dialog.alert("!Ops something went wrong");
        });
    },
    startSurvey() {
      this.$router.push({ name: "ASLTrial" });
    }
  },
  data: () => ({
    isLoading: false,
    companyShortId: "urp@aslcittaditorino.it",
    hasError: false,
    errorMessage: "Ops! Something went wrong",
    lnsCode: ""
  })
};
</script>

<style scoped>
.main-wrapper {
  height: 100vh;
}
.code-input {
  display: flex;
  justify-content: center;
}
.code-input input[type="text"] {
  width: 50px;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem;
  font-size: 2.5rem;
  margin: 0.25rem;
  border: 1px solid #f85d05;
  text-align: center;
}
.card-content {
  max-width: 400px;
  margin: auto;
}

/** Transition animation for vue */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
