<template>
  <div>
    <div class="radio-options">
      <p class="mb-4" v-if="question">{{ question }}</p>
      <div class="radio-item">
        <label v-for="(option, index) of options" :key="index">
          <input
            type="radio"
            :name="name"
            :value="option.value"
            @click="setValue(option.value)"
          />
          <div class="radio-box">
            <span>{{ optionsTranslation[index].label || option.label }}</span>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FormConfirmationBox",
  props: {
    options: {
      type: Array,
      validator: (items) =>
        items.every((v) => v.label !== undefined && v.value !== undefined),
      default: () => [
        { label: "Yes", value: "YES" },
        { label: "No", value: "NO" },
      ],
    },
    optionsTranslation: {
      type: Array,
      default: () => [
        { label: null, value: "YES" },
        { label: null, value: "NO" },
      ],
    },
    name: String,
    question: String,
  },
  methods: {
    setValue(value) {
      this.$emit("output", value);
    },
  },
};
</script>
<style scoped>
.radio-options label {
  /* margin: 0 .75rem; */
  cursor: pointer;
}
.radio-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
/* 
.radio-options label:first-of-type{
    margin-bottom: .75rem;
}
 .radio-options label:last-of-type{
    margin-top: .75rem; 
 } */

.radio-box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 145px;
  padding: 0.3rem;
  min-height: 38px;
  background: #ffffff;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  border: 1px solid transparent;
}
input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + .radio-box {
  border: 1px solid #ff4b7f;
  transition: border 0.25s ease-in;
}
@media screen and (max-width: 576px) {
  .radio-box {
    min-width: 120px !important;
  }
}
</style>
