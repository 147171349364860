<template>
  <div class="main-wrapper py-2 px-4 p-lg-0 asl-bg">
    <div class="cmp-logo has-text-centered py-4">
      <img
        src="@/assets/media/img/logo/voicemed-white.svg"
        alt="voicemed logo"
      />
    </div>
    <div class="container">
      <section class="card voicemed-card voicemed-card-centered-medium">
        <div class="card-content p-5">
          <div class="mb-4 has-text-centered">
            <img src="@/assets/media/img/logo/asl.png" alt="asl logo" />
          </div>
          <h1 class="title">{{ $t("asl.welcome.title") || "Welcome!" }}</h1>
          <div class="mb-4">
            <p>
              {{
                $t("asl.welcome.description") ||
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
              }}
              <a @click="isModalActive = true">Policy sulla Privacy</a>
            </p>
          </div>
          <div class="mb-4">
            <p>
              <b>{{ $t("asl.welcome.duration") || "Duration : 2 minutes" }}</b>
            </p>
          </div>
          <div class="content hide-on-phone">
            <checkbox-v-1 :choice="choice" @output="isConsentGiven = $event">
            </checkbox-v-1>
          </div>
          <div class="botton-btn hide-on-phone">
            <b-button
              size="is-large"
              class="button is-primary voicemed-button"
              :disabled="!isConsentGiven"
              @click="goToLoginPage()"
            >
              {{ $t("buttons.letsGo") }}
            </b-button>
          </div>
        </div>
      </section>
      <div class="hide-on-pad mt-4">
        <checkbox-v-1 :choice="choice" @output="isConsentGiven = $event">
        </checkbox-v-1>
      </div>
      <div class="botton-btn hide-on-pad">
        <b-button
          size="is-large"
          class="button is-primary voicemed-button"
          :disabled="!isConsentGiven"
          @click="goToLoginPage()"
        >
          {{ $t("buttons.letsGo") || "Lets Go" }}
        </b-button>
      </div>
    </div>
    <b-modal :active.sync="isModalActive">
      <ASLConsent @close="isModalActive = false"></ASLConsent>
    </b-modal>
  </div>
</template>
<script>
import CheckboxV1 from "@/core/components/ui/form-fields-ui/CheckboxV1.vue";
import ASLConsent from "./partials/ASLConsent.vue";
export default {
  components: { CheckboxV1, ASLConsent },
  name: "ASLIndex",
  methods: {
    goToLoginPage() {
      this.$router.push({ name: "ASLLogin" });
    },
    hello() {
      console.log("Hello how are you");
    }
  },
  data: function() {
    return {
      isConsentGiven: false,
      isModalActive: false,
      choice: {
        label:
          "Ho letto e compreso la Privacy Policy dello studio e sono d'accordo con il trattamento dei miei dati.",
        value: false,
        name: "aslTermsAndCond"
      }
    };
  }
};
</script>
<style scoped>
.botton-btn {
  display: flex;
  justify-content: center;
  widows: 100%;
  margin-top: 4rem;
}

@media screen and (max-width: 576px) {
  .title {
    font-size: 24px;
  }
  .hide-on-phone {
    display: none;
  }
}

@media screen and (min-width: 576px) {
  .hide-on-pad {
    display: none;
  }
}

.fixed-btn {
  position: absolute;
  bottom: 2rem;
  left: 0;
  width: 100%;
  padding: 0 1.5rem;
}
.asl-bg {
  background: linear-gradient(
      360deg,
      rgba(59, 50, 98, 0.712) 5.85%,
      rgba(59, 50, 98, 0.712) 100%
    ),
    url("/images/bg/overlay-lns.png") 0 / cover no-repeat;
  min-height: 100vh;
}
.code-input {
  display: flex;
  justify-content: center;
}

.code-input input[type="text"] {
  width: 50px;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem;
  font-size: 2.5rem;
  margin: 0.25rem;
  border: 1px solid #f85d05;
  text-align: center;
}
</style>
