<template>
  <div class="container">
    <div class="radio-options">
      <label
        v-for="(country, index) of countries"
        :key="index"
        :class="{ disabled: country.disabled }"
      >
        <input
          type="radio"
          :name="'countries-list'"
          :value="country.value"
          @click="setValue(country.code)"
          :checked="country.value == radioValue"
          :disabled="country.disabled"
        />
        <div class="radio-box">
          <span class="country-item">
            <span class="symbol symbol-20 mr-3">
              <img :src="country.logo" v-if="!country.disabled" />
              <span class="disabled-flag" v-else></span>
            </span>
            <span> {{ country.label }}</span>
          </span>
        </div>
      </label>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "CountrySelection",
  props: {
    options: {
      type: Array,
      validator: items =>
        items.every(v => v.label !== undefined && v.value !== undefined)
    },
    translation: {
      type: Array,
      validator: items =>
        items.every(v => v.label !== undefined && v.value !== undefined)
    },
    name: String,
    radioValue: { type: String, default: null },
    countries: {
      type: Array,
      validator: items =>
        items.every(
          v =>
            v.label !== undefined &&
            v.value !== undefined &&
            v.code !== undefined &&
            v.logo !== undefined
        ),
      default: () => [
        {
          label: "English",
          value: false,
          disabled: false,
          logo: "/images/flags/svg/216-england.svg",
          code: "en"
        },
        {
          label: "French",
          value: false,
          disabled: false,
          logo: "/images/flags/svg/195-france.svg",
          code: "fr"
        },
        {
          label: "German",
          value: false,
          disabled: false,
          logo: "/images/flags/svg/162-germany.svg",
          code: "de"
        },
        {
          label: "Italian",
          value: false,
          disabled: false,
          logo: "/images/flags/svg/013-italy.svg",
          code: "it"
        }
      ]
    }
  },
  computed: mapState(["locale"]),
  watch: {
    locale() {
      this.$router.replace({ params: { lang: this.locale } }).catch(() => {});
    }
  },
  methods: {
    setValue(value) {
      this.$store.dispatch("changeLocale", value);
      console.log(this.$store.state.locale);
      this.$emit("countrySelected", value);
    }
  }
};
</script>
<style lang="scss" scoped>
.country-item {
  display: flex;
  width: 100px;
}
.radio-options label {
  margin: 0 0.75rem;
  cursor: pointer;
}

.radio-options label.disabled {
  cursor: default;
  color: #bdbdbd !important;
}

.radio-options label:first-of-type {
  margin-bottom: 0.75rem;
}
.radio-options label:last-of-type {
  margin-top: 0.75rem;
}

.radio-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  min-height: 38px;
  background: #ffffff;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  border: 1px solid transparent;
}
input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + .radio-box {
  border: 1px solid #ff4b7f;
  transition: border 0.25s ease-in;
}
.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.42rem;
}
.symbol.symbol-20 > img {
  width: 100%;
  max-width: 20px;
  height: 20px;
}

@media (min-width: 1400px) {
  .symbol > img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
}

@media (min-width: 1200px) {
  .symbol > img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
}

@media (min-width: 992px) {
  .symbol > img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
}

@media (min-width: 768px) {
  .symbol > img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
}
@media (min-width: 576px) {
  .symbol > img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
  .symbol > img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }
  .symbol > img {
    display: flex;
    border-radius: 0.42rem;
  }
}
.disabled-flag {
  display: inline-flex;
  background: #f6f6f6;
  width: 20px;
  height: 20px;
  border-radius: 0.42rem;
}
</style>
