<template>
  <b-navbar :fixed-top="true" :shadow="true" :wrapper-class="'container'">
    <template slot="brand">
      <b-navbar-item
        tag="router-link"
        :to="{ path: '/' + this.$store.state.locale }"
      >
        <img
          src="/images/logo.png"
          height="50"
          alt="Lightweight UI components for Vue.js based on Bulma"
        />
      </b-navbar-item>
    </template>
    <template slot="end">
      <div class="language-select-wrapper">
        <select class="option-select" v-model="lang">
          <option value="en">
            English
          </option>
          <option value="it">
            Italian
          </option>
          <option value="fr">
            French
          </option>
        </select>
      </div>
      <b-dropdown
        position="is-bottom-left"
        append-to-body
        aria-role="menu"
        v-if="loggedIn"
      >
        <template #trigger>
          <a class="navbar-item" role="button">
            <span>Hello, {{ currentUserFirstName }}</span>
            <b-icon icon="menu-down"></b-icon>
          </a>
        </template>

        <b-dropdown-item value="logout" aria-role="menuitem" @click="logout()">
          <b-icon icon="logout"></b-icon>
          Logout
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-navbar>
</template>

<script>
import jwtService from "@/core/services/jwt.service";

export default {
  name: "Header",
  data: () => ({
    langs: [],
    isLoggedIn: !jwtService.isTokenExpired()
  }),
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace(`/${this.$store.state.locale}`).catch(() => {});
    }
  },
  watch: {
    //   lang (newValue) {
    //       this.$store.dispatch('changeLocale', newValue)
    //   }
  },
  computed: {
    lang: {
      get: function() {
        return this.$store.state.locale;
      },
      set: function(newVal) {
        console.log(newVal);
        this.$store.dispatch("changeLocale", newVal);
      }
    },
    currentUserFirstName: function() {
      let name = String(this.$store.state.currentUser.name);
      name = name.replace(/_/g, " ");
      name = name.split(" ")[0];
      String.prototype.toSentenceCase = function() {
        return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
      };
      return String(name).toSentenceCase();
    },
    loggedIn: function() {
      return this.$store.state.currentUser.logged_in;
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar-item {
  img {
    max-height: 3rem;
  }
}
.option-select {
  border: none;
  color: #ff4b7f !important;
  font-weight: bold;
  font-size: 12px;
  font-style: italic;
  &:focus {
    outline: none;
  }
}
.language-select-wrapper {
  display: flex;
  align-items: center;
  justify-items: center;
}
</style>
