import axios from "axios";
import store from "@/store";
export const postSurvey = async function (payload) {
  if (store.state.currentUser.is_anon) {
    return await axios.post(
      `${process.env.VUE_APP_RECORD_URL}/survey/questionaire/anonymous/${store.state.anonId}`,
      payload
    );
  }
  return await axios.post(
    `${process.env.VUE_APP_RECORD_URL}/survey/questionaire/organic`,
    payload
  );
};

export const postTrialSurvey = async function (payload, query) {
  console.log(query);
  const queryString = query
    ? Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&")
    : "";
  console.log(queryString);
  return await axios.post(
    `${process.env.VUE_APP_RECORD_URL}/trial/survey/participant${
      queryString.length > 0 ? "?" + queryString : ""
    }`,
    payload
  );
};

export const getSurveyByParticipantId = async function (
  participantId,
  isTrial
) {
  if (isTrial) {
    return await axios.get(
      `${process.env.VUE_APP_RECORD_URL}/trial/survey/participant/${participantId}`
    );
  }
  if (store.state.currentUser.is_anon) {
    return await axios.get(
      `${process.env.VUE_APP_RECORD_URL}/survey/questionaire/anonymous/${participantId}`
    );
  }
  return await axios.get(
    `${process.env.VUE_APP_RECORD_URL}/survey/questionaire/organic`
  );
};

export const getTrialSurveyForParticipant = async function () {
  return await axios.get(
    `${process.env.VUE_APP_RECORD_URL}/trial/survey/participant`
  );
};

export const postSample = async function (surveyType, recordType, blob) {
  const formData = new FormData();
  formData.append(
    "file",
    new File([blob], recordType + ".wav", { type: "audio/wav" })
  );
  formData.append("sampleType", surveyType);
  if (store.state.currentUser.is_anon) {
    return await axios.post(
      `${process.env.VUE_APP_RECORD_URL}/survey/audio-sample/${recordType}/anonymous/${store.state.anonId}`,
      formData
    );
  }
  return await axios.post(
    `${process.env.VUE_APP_RECORD_URL}/survey/audio-sample/${recordType}`,
    formData
  );
};

export const postTrialSample = async function (
  surveyType,
  recordType,
  blob,
  query
) {
  const queryString = query
    ? Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&")
    : "";
  const formData = new FormData();
  formData.append(
    "file",
    new File([blob], recordType + ".wav", { type: "audio/wav" })
  );
  formData.append("sampleType", surveyType);
  return await axios.post(
    `${
      process.env.VUE_APP_RECORD_URL
    }/trial/audio-sample/${recordType}/participant${
      queryString.length > 0 ? "?" + queryString : ""
    }`,
    formData
  );
};

export const postGuestEmail = async function (email) {
  return await axios.post(`${process.env.VUE_APP_RECORD_URL}/guests/emails`, {
    email: email,
  });
};

export const getTrialPageStats = async function () {
  return await axios.get(
    `${process.env.VUE_APP_RECORD_URL}/trial/page-statistics`
  );
};

export const defaultCovidQuestionaires = [
  {
    id: 1,
    question: "How old are you?",
    answer: null,
    answerFieldType: "RADIO_BUTTON",
    enabled: true,
    mutlioptions: true,
    mutlianswer: false,
    required: true,
    requiredIfAnswered: null,
    options: [
      { label: "Under 20", value: "UNDER-20" },
      { label: "20-29", value: "20-29" },
      { label: "30-39", value: "30-39" },
      { label: "40-49", value: "40-49" },
      { label: "50-59", value: "50-59" },
      { label: "60-69", value: "60-69" },
      { label: "70-79", value: "70-79" },
      { label: "80-89", value: "80-89" },
      { label: "Over 89", value: "OVER-89" },
    ],
    choices: [],
  },
  {
    id: 2,
    question: "Which is your gender?",
    answer: null,
    answerFieldType: "SELECT_OPTION",
    enabled: true,
    mutlioptions: true,
    mutlianswer: false,
    required: true,
    requiredIfAnswered: null,
    options: [
      { label: "Male", value: "Male" },
      { label: "Female", value: "Female" },
      {
        label: "Non-conformant or non-binary",
        value: "Non-conformant or non-binary",
      },
      { label: "Prefer not to say", value: "Prefer not to say" },
    ],
    choices: [],
  },
  {
    id: 3,
    question: "In which city and country are you currently living?",
    answer: null,
    answerFieldType: "INPUT_CITY_COUNTRY",
    enabled: true,
    mutlioptions: false,
    mutlianswer: false,
    required: true,
    requiredIfAnswered: null,
    options: [],
    choices: [],
  },
  {
    id: 4,
    question: "What is your native language?",
    answer: null,
    answerFieldType: "INPUT_LANGUAGE",
    enabled: true,
    mutlioptions: false,
    mutlianswer: false,
    required: true,
    requiredIfAnswered: null,
    options: [],
    choices: [],
  },
  {
    id: 5,
    question: "Have you taken a COVID-19 test in the last 14 days?",
    answer: null,
    answerFieldType: "SELECT_OPTION",
    enabled: true,
    mutlioptions: true,
    mutlianswer: false,
    required: true,
    requiredIfAnswered: null,
    options: [
      { label: "Yes", value: "YES" },
      { label: "No", value: "NO" },
    ],
    choices: [],
  },
  {
    id: 6,
    question: "What was the result of this COVID-19 test?",
    answer: null,
    answerFieldType: "SELECT_OPTION",
    enabled: false,
    mutlioptions: true,
    mutlianswer: false,
    required: true,
    requiredIfAnswered: 5,
    options: [
      { label: "Postive", value: "POSITIVE" },
      { label: "Negative", value: "NEGATIVE" },
      { label: "Unknown", value: "UNKNOWN" },
    ],
    choices: [],
  },
  {
    id: 7,
    question:
      "Have you had close contact with a confirmed COVID-19 case in the last 10 days?",
    answer: null,
    answerFieldType: "SELECT_OPTION",
    enabled: true,
    mutlioptions: true,
    mutlianswer: false,
    required: true,
    requiredIfAnswered: null,
    options: [
      { label: "Yes", value: "YES" },
      { label: "No", value: "NO" },
    ],
    choices: [],
  },
  {
    id: 8,
    question: "In the past 24 hours, have you had a cough?",
    answer: null,
    answerFieldType: "SELECT_OPTION",
    enabled: true,
    mutlioptions: true,
    mutlianswer: false,
    required: true,
    requiredIfAnswered: null,
    options: [
      { label: "Yes", value: "YES" },
      { label: "No", value: "NO" },
    ],
    choices: [],
  },
  {
    id: 9,
    question:
      "Do you cough so frequently that it disrupts our daily activities or nighttime sleep?",
    answer: null,
    answerFieldType: "SELECT_OPTION",
    enabled: false,
    mutlioptions: true,
    mutlianswer: false,
    required: true,
    requiredIfAnswered: 8,
    options: [
      { label: "Yes", value: "YES" },
      { label: "No", value: "NO" },
    ],
    choices: [],
  },
  {
    id: 10,
    question: "Was it dry or wet?",
    answer: null,
    answerFieldType: "SELECT_OPTION",
    enabled: false,
    mutlioptions: true,
    mutlianswer: false,
    required: true,
    requiredIfAnswered: 8,
    options: [
      {
        label:
          "Wet. Coughing brings up mucus or phlegm that can be felt on the throat and/or mouth",
        value: "WET",
      },
      {
        label:
          "I don’t feel mucus or phlegm on my mouth or throat when I cough",
        value: "DRY",
      },
      {
        label: "I don’t Know",
        value: "UNKNOWN",
      },
    ],
    choices: [],
  },
  {
    id: 11,
    question: "How do you rate the intensity of your cough?",
    answer: null,
    answerFieldType: "SELECT_OPTION",
    enabled: false,
    mutlioptions: true,
    mutlianswer: false,
    required: true,
    requiredIfAnswered: 8,
    options: [
      {
        label:
          "I feel it deep in my chest and/or it is painful and/or my whole upper body shakes when I cough",
        value: "INTENSE",
      },
      { label: "Intermediate", value: "INTERMEDIATE" },
      {
        label:
          "Mild. I feel a discomfort in my throat and an urge to cough to relieve it.",
        value: "MILD",
      },
    ],
    choices: [],
  },
  {
    id: 12,
    question:
      "In the past 24 hours, did you feel that you were more quickly out of breath than usual?",
    answer: null,
    answerFieldType: "SELECT_OPTION",
    enabled: true,
    mutlioptions: true,
    mutlianswer: false,
    required: true,
    requiredIfAnswered: null,
    options: [
      { label: "Yes", value: "YES" },
      { label: "No", value: "NO" },
    ],
    choices: [],
  },
  {
    id: 13,
    question:
      "Please select if you have any of the following symptoms today (all that apply)",
    answer: null,
    answerFieldType: "INPUT_CHECKBOX",
    enabled: true,
    mutlioptions: false,
    mutlianswer: true,
    required: true,
    requiredIfAnswered: null,
    options: [],
    choices: [
      { name: "fever", value: false, label: "Fever" },
      { name: "tiredness", value: false, label: "Feeling tired or weak" },
      { name: "diarrhea", value: false, label: "Diarrhea" },
      { name: "headache", value: false, label: "Headache" },
      {
        name: "lossOfTaste",
        value: false,
        label: "Loss of taste and/or smell",
      },
      { name: "chestPain", value: false, label: "Chest Pain" },
      { name: "soreThroat", value: false, label: "Sore Throat" },
      {
        name: "runnyOrStuffyNose",
        value: false,
        label: "Runny or stuffy nose",
      },
      { name: "none", value: false, label: "None" },
    ],
  },
  {
    id: 14,
    question:
      "Please select if you have been diagnosed with any of the following conditions (checkbox, multiple selections possible)",
    answer: null,
    answerFieldType: "INPUT_CHECKBOX",
    enabled: true,
    mutlioptions: false,
    mutlianswer: true,
    required: true,
    requiredIfAnswered: null,
    options: [],
    choices: [
      {
        name: "highBloodPressure",
        value: false,
        label: "High blood pressure",
      },
      { name: "diabetes", value: false, label: "Diabetes" },
      { name: "heartDisease", value: false, label: "Heart Disease" },
      { name: "overWeight", value: false, label: "Obesity/Overweight" },
      {
        name: "lungCancer",
        value: false,
        label: "Lung cancer active in the last 5 years",
      },
      {
        name: "otherCancer",
        value: false,
        label: "Other active cancer in the last 5 years",
      },
      { name: "asthma", value: false, label: "Asthma" },
      {
        name: "copd",
        value: false,
        label: "Obstructive pulmonary disease",
      },
      {
        name: "otherRespiratoryDisease",
        value: false,
        label: "Other Respiratory Disease",
      },
      { name: "none", value: false, label: "None" },
    ],
  },
  {
    id: 15,
    question: "Do you smoke, or have you ever smoked?",
    answer: null,
    answerFieldType: "SELECT_OPTION",
    enabled: true,
    mutlioptions: false,
    mutlianswer: false,
    required: true,
    requiredIfAnswered: null,
    options: [
      {
        label: "Never smoked",
        value: "NEVER_SMOKED",
      },
      {
        label: "Ex-smoker",
        value: "EX_SMOKER",
      },
      {
        label: "Current smoker but I don’t smoke every day",
        value: "SMOKE_NOT_OFTEN",
      },
      {
        label: "I current smoke daily",
        value: "SMOKE_OFTEN",
      },
    ],
    choices: [],
  },
  {
    id: 16,
    question:
      "If you want to receive information about our research project and be up-to-date with our progress, please share your e-mail address",
    answer: null,
    answerFieldType: "INPUT_EMAIL",
    enabled: true,
    mutlioptions: false,
    mutlianswer: false,
    required: true,
    requiredIfAnswered: null,
    options: [],
    choices: [],
  },
  {
    id: 17,
    question: "Which device did you use for this survey?",
    answer: null,
    answerFieldType: "SELECT_OPTION",
    enabled: true,
    mutlioptions: false,
    mutlianswer: false,
    required: true,
    requiredIfAnswered: null,
    options: [
      {
        label: "Web",
        value: "WEB",
      },
      {
        label: "Mobile",
        value: "MOBILE",
      },
    ],
    choices: [],
  },
];

export default {
  postSurvey,
  defaultCovidQuestionaires,
  postSample,
  postTrialSample,
  postTrialSurvey,
  getSurveyByParticipantId,
  getTrialSurveyForParticipant,
  getTrialPageStats,
  postGuestEmail,
};
