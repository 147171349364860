import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import i18n, { selectedLocale } from "@/i18n";
import jwtService from "@/core/services/jwt.service.js";
import authService from "@/core/services/auth.service";
import surveyService from "@/core/services/survey.service";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    locale: selectedLocale,
    currentUser: {
      name: null,
      username: null,
      is_active: true,
      logged_in: false,
      is_anon: true,
      is_client: false,
      is_end_user: false,
    },
    survey: {
      questions: [],
      translation: [],
      completionPercentage: 0,
    },
    consent: {
      questions: [],
    },
    anonId: null,
  },
  getters: {
    getSurveyQuestionsTranslation: (state) => {
      return state.survey.translation;
    },
    getSurveyQuestionsTranslationById: (state) => (id) => {
      return state.survey.translation.find((q) => q.id == id);
    },
    getSurveyQuestions: (state) => {
      return state.survey.questions;
    },
    getSurveyQuestionById: (state) => (id) => {
      return state.survey.questions.find((q) => q.id == id);
    },
    getSurveyQuestionByTag: (state) => (tag) => {
      return state.survey.questions.find((q) => q.tag == tag);
    },
    getSurveyCompletionPercentage: (state) => {
      return state.survey.completionPercentage;
    },
    getConsentQuestion: (state) => () => {
      return state.consent.questions;
    },
    getConsentQuestionById: (state) => (id) => {
      return state.consent.questions.find((q) => q.id == id);
    },
    getCurrentUser: (state) => {
      return state.currentUser;
    },
    // eslint-disable-next-line no-unused-vars
    getIsLoggedIn: (state) => {
      return !jwtService.isTokenExpired();
    },
    getAnonId: (state) => {
      return state.anonId;
    },
  },
  mutations: {
    updateLocale(state, newLocale) {
      state.locale = newLocale;
    },
    updateSurveyQuestionsTranslation(state, newSurveyQuestionsTranslation) {
      state.survey.translation = newSurveyQuestionsTranslation;
    },
    updateSurveyQuestions(state, newSurveyQuestions) {
      state.survey.questions = newSurveyQuestions;
    },
    updateSurveyQuestionById(state, { id, questionItem }) {
      const foundIndex = state.survey.questions.findIndex((a) => a.id == id);
      state.survey.questions[foundIndex] = questionItem;
    },
    updateConsentQuestions(state, newConsentQuestions) {
      state.consent.questions = newConsentQuestions;
    },
    updateConsentQuestionById(state, { id, questionItem }) {
      const foundIndex = state.consent.questions.findIndex((a) => a.id == id);
      state.consent.questions[foundIndex] = questionItem;
    },
    updateSurveyCompletionPercentage(state, value) {
      state.survey.completionPercentage = value;
    },
    updateLoggedInAuthUser(state, value) {
      state.currentUser = {
        username: value.username,
        name: value.name,
        is_active: value.is_active,
        logged_in: true,
        is_anon: value.is_anon || true,
        is_client: value.is_client,
        is_end_user: value.is_end_user,
      };
    },
    updateAnonId(state, value) {
      state.anonId = value;
    },
    resetLoggedInState(state) {
      state.currentUser = {
        name: null,
        username: null,
        is_active: true,
        logged_in: false,
        is_anon: true,
        is_client: false,
        is_end_user: false,
      };
      state.anonId = null;
      state.survey = {
        questions: [],
        translation: [],
        completionPercentage: 0,
      };
    },
  },
  actions: {
    changeLocale({ commit }, newLocale) {
      i18n.locale = newLocale; // important!
      commit("updateLocale", newLocale);
    },
    changeSurveyQuestionsTranslation(
      { commit },
      newSurveyQuestionsTranslation
    ) {
      commit("updateSurveyQuestionsTranslation", newSurveyQuestionsTranslation);
    },
    changeSurveyQuestions({ commit }, newSurveyQuestions) {
      commit("updateSurveyQuestions", newSurveyQuestions);
    },
    changeSurveyQuestionById({ commit }, { id, updatedQuestionItem }) {
      commit("updateSurveyQuestionById", id, updatedQuestionItem);
    },

    changeSurveyCompletionPercentage({ commit }, value) {
      commit("updateSurveyCompletionPercentage", value);
    },
    changeSurveyQuestionIfLoggedUserDataExists({ commit }, { isTrial }) {
      return surveyService
        .getSurveyByParticipantId(this.state.anonId, isTrial)
        .then((response) => {
          const surveyData = response.data.data;
          commit("updateSurveyQuestions", surveyData.questions);
          return Promise.resolve(surveyData);
        })
        .catch();
    },
    changeTrialSurveyQuestions({ commit }) {
      return surveyService
        .getTrialSurveyForParticipant()
        .then((response) => {
          const surveyData = response.data.data;
          commit("updateSurveyQuestions", surveyData.questions);
          return Promise.resolve(surveyData);
        })
        .catch();
    },
    changeTrialSurveyQuestionsAdvanced({ commit }) {
      return surveyService
        .getTrialSurveyForParticipant()
        .then((response) => {
          // TODO : this could be changed later on
          const surveyData = response.data.data;
          const newSurveyQuestions = surveyData.questions;
          if (this.state.survey.questions.length > 1) {
            surveyData.questions = this.state.survey.questions.map((v) => {
              const foundIndex = newSurveyQuestions.findIndex(
                (q) => q.id == v.id
              );
              if (foundIndex >= 0) {
                v.answer = newSurveyQuestions[foundIndex].answer;
                //check if options has changed and if answer selected isnt in the options list, change answer to null
                if (
                  newSurveyQuestions[foundIndex].options &&
                  newSurveyQuestions[foundIndex].options.length > 0 &&
                  newSurveyQuestions[foundIndex].mutlioptions
                ) {
                  if (!v.options.find((op) => op.value == v.answer)) {
                    v.answer = null;
                  }
                }
                // update choices based on choice selected
                if (newSurveyQuestions[foundIndex].choices) {
                  v.choices = v.choices.map((c) => {
                    const choiceIndex = newSurveyQuestions[
                      foundIndex
                    ].choices.findIndex((xi) => xi.name == c.name);
                    if (choiceIndex >= 0) {
                      c.value =
                        newSurveyQuestions[foundIndex].choices[
                          choiceIndex
                        ].value;
                    }
                    return c;
                  });
                }
                if (
                  newSurveyQuestions[foundIndex].relativeAnswerForChoices &&
                  v.relativeAnswerForChoices
                ) {
                  v.relativeAnswerForChoices =
                    newSurveyQuestions[foundIndex].relativeAnswerForChoices;
                }
              }
              return v;
            });
          }
          commit("updateSurveyQuestions", surveyData.questions);
          return Promise.resolve(surveyData);
        })
        .catch();
    },
    changeConsentQuestionById({ commit }, { id, updatedQuestionItem }) {
      commit("updateConsentQuestionById", id, updatedQuestionItem);
    },
    setLoggedInAuthUser(
      { commit },
      { user, access_token, expiration, refresh_token }
    ) {
      commit("updateLoggedInAuthUser", user);
      jwtService.saveToken(access_token);
      jwtService.saveToStorage("refresh_token", refresh_token);
      jwtService.saveToStorage("exp", expiration);
    },
    fetchCurrentUser({ commit }) {
      authService
        .fetchLoggedInUser()
        .then((result) => {
          commit("updateLoggedInAuthUser", result.user);
        })
        .catch();
    },
    changeAnonId({ commit }, value) {
      commit("updateAnonId", value);
    },
    logout({ commit }) {
      authService.logout();
      commit("resetLoggedInState");
    },
    // eslint-disable-next-line no-unused-vars
    postSurvey({ commit }, payload) {
      return surveyService.postSurvey(payload);
    },
  },
  plugins: [
    createPersistedState({ paths: ["locale", "currentUser", "anonId"] }),
  ],
  modules: {},
});
