<template>
  <div class="main-wrapper py-2 px-4 p-lg-0 lns-bg">
    <div class="cmp-logo has-text-centered py-4">
      <img
        src="@/assets/media/img/logo/voicemed-white.svg"
        alt="voicemed logo"
      />
    </div>
    <div class="container">
      <section class="card voicemed-card voicemed-card-centered-medium">
        <div class="card-content p-5">
          <div class="mb-4">
            <img src="@/assets/media/img/logo/lns-colored.svg" alt="lns logo" />
          </div>
          <h1 class="title">{{ $t("lns.welcome.title") }}</h1>
          <div class="mb-4">
            <p v-html="$t('lns.welcome.description')">
              You are invited to take part in a new research study called COVID+
              VOICES conducted by <b class="vmed-text-gradient">VoiceMed</b> and
              the
              <b class="vmed-text-gradient"
                >Laboratoire National de Santé (LNS).</b
              >
            </p>
          </div>
          <div class="mb-4">
            <p v-html="$t('lns.welcome.information')">
              The next pages are intended to better inform you, so that you can
              give your consent or refuse to participate in this study
            </p>
          </div>
          <div class="botton-btn hide-on-phone">
            <b-button
              size="is-large"
              class="button is-primary voicemed-button"
              @click="goToLoginPage()"
            >
              {{ $t("buttons.letsGo") }}
            </b-button>
          </div>
        </div>
      </section>
      <div class="botton-btn hide-on-pad">
        <b-button
          size="is-large"
          class="button is-primary voicemed-button"
          @click="goToLoginPage()"
        >
          {{ $t("buttons.letsGo") }}
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LnsIndex",
  methods: {
    goToLoginPage() {
      this.$router.push({ name: "LnsAboutStudy" });
    }
  }
};
</script>
<style scoped>
.botton-btn {
  display: flex;
  justify-content: center;
  widows: 100%;
  margin-top: 4rem;
}

@media screen and (max-width: 576px) {
  .title {
    font-size: 24px;
  }
  .hide-on-phone {
    display: none;
  }
}

@media screen and (min-width: 576px) {
  .hide-on-pad {
    display: none;
  }
}

.fixed-btn {
  position: absolute;
  bottom: 2rem;
  left: 0;
  width: 100%;
  padding: 0 1.5rem;
}
.lns-bg {
  background: linear-gradient(
      360deg,
      rgba(0, 176, 235, 0.651) 5.85%,
      rgba(0, 137, 235, 0.747) 100%
    ),
    url("/images/bg/overlay-lns.png") 0 / cover no-repeat;
  min-height: 100vh;
}
.code-input {
  display: flex;
  justify-content: center;
}

.code-input input[type="text"] {
  width: 50px;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem;
  font-size: 2.5rem;
  margin: 0.25rem;
  border: 1px solid #f85d05;
  text-align: center;
}
</style>
