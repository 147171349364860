import axios from 'axios'
import jwtService from '@/core/services/jwt.service'
import authService from '../services/auth.service'
import createAuthRefreshInterceptor from 'axios-auth-refresh';
// import { NotificationProgrammatic as Notification } from "buefy";

const ApiService =  {

    init(){

    // Using Auth axois refresh library
    createAuthRefreshInterceptor(axios, refreshAuthLogin, {pauseInstanceWhileRefreshing: true})

    // Add a request interceptor
    axios.interceptors.request.use(function (config) {
        // Do something before request is sent
    
        if(jwtService.getToken()){
            config.headers.Authorization =   "Bearer "+jwtService.getToken();
            // axios.defaults.headers.common['Authorization'] = "Bearer "+jwtService.getToken();
        }
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if(error.response && error.response.status == 401){
            // Notification.open({message : "Login Token is expired or invalid", type : 'is-danger', duration : 3000, position: 'is-bottom' });
            // console.log(error)
        }
        return Promise.reject(error);
    });


    },




    

}

const refreshAuthLogin = failedRequest => authService.refreshToken().then(() => {
    failedRequest.response.config.headers['Authorization'] = 'Bearer ' + jwtService.getToken();    
    return Promise.resolve()
})

export default ApiService
