<template>
  <div class="main-wrapper py-2 px-4 p-lg-0 bg-lg-voicemed">
    <div class="cmp-logo has-text-centered py-4">
      <img
        src="@/assets/media/img/logo/voicemed-reversed.svg"
        alt="voicemed logo"
      />
    </div>
    <div class="container">
      <section class="card voicemed-card voicemed-card-centered-medium">
        <div class="card-content">
          <h1 class="title has-text-centered mt-lg-4">
            {{ $t("lns.login.title") }}
          </h1>
          <div>
            <div class="my-5">
              <div class="mb-4">
                <span class="voicemed-label font-weight-bold">{{
                  $t("lns.login.genderLabel")
                }}</span>
                <form-confirmation-box
                  :name="'question-gender-radio'"
                  :options="$t('lns.login.genderQuestion.options', 'en')"
                  :optionsTranslation="$t('lns.login.genderQuestion.options')"
                  @output="genderQuestion.answer = $event"
                ></form-confirmation-box>
              </div>
              <div class="voicemed-field-wrapper mb-2">
                <b-field
                  :label="$t('lns.login.loginFormLabel')"
                  :label-position="'inside'"
                >
                  <b-input
                    class="voicemed-input-field"
                    type="text"
                    v-model="lnsCode"
                    maxlength="10"
                    size="is-medium"
                  ></b-input>
                </b-field>
              </div>
              <div class="voicemed-field-wrapper">
                <b-field
                  :label="$t('fieldLabels.selectAge') || 'Select Age'"
                  :label-position="'inside'"
                >
                  <b-select
                    class="voicemed-input-field"
                    size="is-medium"
                    placeholder="-"
                    expanded
                    v-model="ageQuestion.answer"
                  >
                    <option
                      :value="option.value"
                      v-for="(option, index) of $t(
                        'lns.login.ageQuestion.options',
                        'en'
                      )"
                      :key="option.value"
                    >
                      {{
                        $t("lns.login.ageQuestion.options")[index].label ||
                        option.label
                      }}
                    </option>
                  </b-select>
                </b-field>
              </div>
              <transition name="fade">
                <div class="mt-3 error-wrapper" v-if="hasError">
                  <span>{{ errorMessage }}</span>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="card-bottom bottom w-full">
          <footer class="card-footer">
            <div class="card-footer-item">
              <b-button
                size="is-large"
                class="voicemed-button next-button"
                :disabled="!ageQuestion.answer || !genderQuestion.answer"
                @click.native="submit()"
                >{{ $t("survey.buttons.next") }}</b-button
              >
            </div>
          </footer>
        </div>
      </section>
    </div>
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>
<script>
import { PassCodeWatcher } from "@/core/directives/pass-code-watcher.js";
import authService from "@/core/services/auth.service";
import consentService from "@/core/services/consent.service";
import FormConfirmationBox from "@/core/components/ui/form-fields-ui/FormConfirmationBox.vue";
export default {
  components: { FormConfirmationBox },
  name: "LnsLogin",
  directives: {
    passCodeWatcher: PassCodeWatcher,
  },
  props: {
    genderQuestion: {
      type: Object,
      default: () => ({
        id: 2,
        question: "Gender",
        tag: "GENDER",
        answer: null,
        options: [
          {
            label: "Male",
            value: "Male",
          },
          {
            label: "Female",
            value: "Female",
          },
        ],
      }),
    },
    ageQuestion: {
      type: Object,
      default: () => ({
        id: 1,
        question: "Age",
        tag: "AGE",
        answer: null,
        options: [
          { label: "Under 18", value: "UNDER-18" },
          { label: "18-29", value: "18-29" },
          { label: "30-39", value: "30-39" },
          { label: "40-49", value: "40-49" },
          { label: "50-59", value: "50-59" },
          { label: "60-69", value: "60-69" },
          { label: "70-79", value: "70-79" },
          { label: "80-89", value: "80-89" },
          { label: "Over 89", value: "OVER-89" },
        ],
      }),
    },
  },
  methods: {
    submit() {
      this.isLoading = true;
      this.hasError = false;
      if (this.ageQuestion.answer == "UNDER-18") {
        this.hasError = true;
        this.isLoading = false;
        this.errorMessage = this.$t(`errorLabels.AGE_ERROR`);
        return;
      }
      authService
        .trialLogin(this.companyShortId, this.lnsCode, "COVID_19")
        .then(() => {
          const payload = {
            title: "LNS particapant information",
            label: "COVID_19",
            questions: [this.genderQuestion, this.ageQuestion],
          };
          console.log("am hererere", payload);
          consentService
            .postTrailPersonalInfo(payload)
            .then(() => {
              this.startSurvey();
              this.isLoading = false;
            })
            .catch(() => {
              this.$buefy.dialog.alert("!Ops something went wrong");
            });
        })
        .catch((error) => {
          const errorTitle = error.response.data.title;
          this.errorMessage =
            this.$t(`errorLabels.${errorTitle}`) ||
            error.response.data.detail ||
            this.errorMessage;
          this.isLoading = false;
          this.hasError = true;
          // this.$buefy.dialog.alert("!Ops something went wrong");
        });
    },
    startSurvey() {
      this.$router.push({ name: "LnsSurvey" });
    },
  },
  data: function () {
    return {
      companyShortId: "lns@example.com",
      hasError: false,
      errorMessage: "Ops! Something went wrong",
      isLoading: false,
      lnsCode: "",
    };
  },
};
</script>
<style scoped>
.main-wrapper {
  height: 100vh;
}
.code-input {
  display: flex;
  justify-content: center;
}
.code-input input[type="text"] {
  width: 50px;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem;
  font-size: 2.5rem;
  margin: 0.25rem;
  border: 1px solid #f85d05;
  text-align: center;
}
.card-content {
  max-width: 400px;
  margin: auto;
}

/** Transition animation for vue */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
