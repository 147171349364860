import Vue from "vue";
import VueRouter from "vue-router";
import { languages } from "@/i18n";
import store from "@/store";
import BaseWithHeader from "@/views/base/BaseWithHeader";
import BaseNoHeader from "@/views/base/BaseNoHeader";
import LnsIndex from "../views/pages/lns/LnsIndex";
import LnsCountrySelection from "../views/pages/lns/LnsCountrySelection.vue";
import LnsLogin from "../views/pages/lns/LnsLogin";
import LnsAboutStudy from "../views/pages/lns/LnsAboutStudy";
import ASLIndex from "../views/pages/asl/ASLIndex";
import ASLLogin from "../views/pages/asl/ASLLogin";
import jwtService from "../core/services/jwt.service";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "root",
    beforeEnter(to, from, next) {
      next(store.state.locale);
    },
  },
  {
    path: "/lns",
    redirect: { path: `${store.state.locale}/lns` },
  },
  {
    path: "/asl",
    redirect: { path: `it/asl` },
  },
  {
    path: "/:lang",
    beforeEnter(to, from, next) {
      let lang = to.params.lang;
      if (languages.includes(lang)) {
        store.dispatch("changeLocale", lang);
        return next();
      }
      return next({ path: store.state.locale });
    },
    component: {
      render: (h) => h("router-view"),
    },
    children: [
      {
        path: "",
        redirect: { path: `lns` },
      },
      {
        path: "lns",
        component: BaseNoHeader,
        children: [
          {
            path: "",
            name: "LnsCountrySelection",
            component: LnsCountrySelection,
          },
          {
            path: "introduction",
            name: "LnsIndex",
            component: LnsIndex,
          },
          {
            path: "login",
            name: "LnsLogin",
            component: LnsLogin,
          },
          {
            path: "about-survey",
            name: "LnsAboutStudy",
            component: LnsAboutStudy,
          },
          {
            path: "survey",
            name: "LnsSurvey",
            beforeEnter: (to, from, next) => {
              if (jwtService.isTokenExpired()) {
                return next({
                  name: "LnsLogin",
                  params: { lang: store.state.locale },
                });
              } else {
                return next();
              }
            },
            component: () =>
              import(
                /* webpackChunkName: "lnsRecording" */ "@/views/pages/lns/LnsTrial.vue"
              ),
          },
        ],
      },
      {
        path: "asl",
        component: BaseNoHeader,
        children: [
          {
            path: "",
            name: "ASLIndex",
            component: ASLIndex,
          },
          {
            path: "login",
            name: "ASLLogin",
            component: ASLLogin,
          },
          {
            path: "trial",
            name: "ASLTrial",
            beforeEnter: (to, from, next) => {
              if (jwtService.isTokenExpired()) {
                return next({
                  name: "ASLLogin",
                  params: { lang: store.state.locale },
                });
              } else {
                return next();
              }
            },
            component: () =>
              import(
                /* webpackChunkName: "asltrail" */ "@/views/pages/asl/ASLTrial.vue"
              ),
          },
        ],
      },
      {
        path: "survey",
        component: BaseWithHeader,
        children: [
          {
            path: "",
            name: "SurveyIndex",
            component: () =>
              import(
                /* webpackChunkName: "surveyindex" */ "@/views/pages/survey/Index.vue"
              ),
          },
          {
            path: "covid",
            name: "Covid",
            beforeEnter: (to, from, next) => {
              if (jwtService.isTokenExpired()) {
                return next({
                  name: "SurveyIndex",
                  params: { lang: store.state.locale },
                });
              } else {
                return next();
              }
            },
            component: () =>
              import(
                /* webpackChunkName: "surveycovid" */ "@/views/pages/survey/covid/CovidQuestions.vue"
              ),
          },
        ],
      },
      {
        path: "about",
        name: "About",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/pages/index/About.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
