<template>
  <div class="container">
    <terms-and-conditions
      v-if="currentPage == 0"
      @previousStep="previous()"
      @nextStep="next()"
      @close="close()"
      :hideOption="true"
      :isPopup="true"
      :termsAndConditions="$t('asl.privacyPolicy')"
    ></terms-and-conditions>
  </div>
</template>
<script>
import TermsAndConditions from "@/core/components/ui/consent-agreement/TermsAndConditions.vue";
export default {
  name: "ASLConsent",
  components: { TermsAndConditions },
  data: () => ({
    isConsentGiven: false,
    currentPage: 0
  }),
  methods: {
    next() {
      // if (this.currentPage >= 3)
      this.$emit("next");
      // else this.currentPage++
    },
    previous() {
      this.currentPage--;
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>
<style scoped>
img {
  height: 100px;
}
.small-img {
  max-height: 400px;
}

.congrats-text {
  font-weight: bold;
  font-size: 30px;
  color: #ff4b7f !important;
}
.card {
  min-height: 520px;
  margin-bottom: 2rem;
}
.question-item {
  margin-top: 4rem;
}
.next-button:hover {
  color: #fff !important;
}
@media screen and (max-width: 576px) {
  .card-bottom {
    position: fixed;
    bottom: 0;
  }
  .next-button {
    min-width: 230px !important;
  }
}
</style>
